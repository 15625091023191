import { useEffect, useState } from "react";
import { useLogs } from "../../providers/LogProvider";
import { BannerNotification } from "../BannerNotification";
import { Button, Flex, Form, Input, InputNumber, Modal } from "antd";
import { sendForm } from "../../api/logs";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { TokenType } from "../../protectedRoute";

export const NOTIFICATION_TRESHOLD = 60;

type PlanNotificationForm = {
  email: string;
  message: string;
  teamId: string;
  estimation: number;
};

export const PlanNotifcation = () => {
  const { planDetails } = useLogs();

  const [decodedToken, setDecodedToken] = useState<JwtPayload | null>(null);
  const [showExceededBanner, setShowExceededBanner] = useState(false);
  const [showTresholdBanner, setShowTresholdBanner] = useState(false);
  const [showUpgradePopup, setUpgradePopupVisible] = useState(false);

  const token = localStorage.getItem("token");
  if (!token) return null;

  useEffect(() => {
    if (token) {
      const decTok = jwtDecode(token);
      setDecodedToken(decTok);
    }
  }, [token]);

  const submitForm = async (values: PlanNotificationForm) => {
    await sendForm(token, {
      ...values,
      teamId: (decodedToken as TokenType).teamId,
    });
    setUpgradePopupVisible(false);
  };

  useEffect(() => {
    if (planDetails) {
      if (planDetails.isExceeded) {
        setShowExceededBanner(true);
        setShowTresholdBanner(false);
        return;
      }

      if (
        planDetails.usedPercent >= NOTIFICATION_TRESHOLD &&
        !localStorage.getItem("planNotification")
      ) {
        setShowTresholdBanner(true);
      }
    }
  }, [planDetails]);

  const onClose = () => {
    localStorage.setItem("planNotification", "true");
    setShowTresholdBanner(false);
  };

  if (
    !planDetails ||
    planDetails.usedPercent < NOTIFICATION_TRESHOLD ||
    !decodedToken
  )
    return null;

  return (
    <div>
      {showTresholdBanner && (
        <BannerNotification
          closable
          onClose={onClose}
          headline="You're approaching your limit!"
        >
          <p>
            {`You've used ${planDetails.usedPercent.toFixed(0)}% of your ${
              planDetails.limitMb / 1024
            }GB monthly limit. Keep an eye on your
                usage to ensure uninterrupted service. If you anticipate needing
                more storage, please consider upgrading your plan. `}
            Contact us via email{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="mailto:hello@logabyss.com"
            >
              (hello@logabyss.com)
            </a>{" "}
            or chat for more details.
          </p>
        </BannerNotification>
      )}
      {showExceededBanner && (
        <BannerNotification headline="Please upgrade your plan to resume collecting logs">
          <Flex vertical align="center">
            <p>
              You've exceeded your {planDetails.limitMb / 1024}GB monthly limit.
              Please contact us via email{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="mailto:hello@logabyss.com"
              >
                (hello@logabyss.com)
              </a>{" "}
              or chat to upgrade your storage and keep the service
              uninterrupted.
            </p>
            {/* <Button onClick={() => setUpgradePopupVisible(true)} type="primary">
              Upgrade
            </Button> */}
          </Flex>
        </BannerNotification>
      )}

      <Modal
        title="Upgrade request"
        open={showUpgradePopup}
        onOk={() => setUpgradePopupVisible(false)}
        onCancel={() => setUpgradePopupVisible(false)}
        footer={null}
      >
        <Flex vertical align="center">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600, width: "100%" }}
            initialValues={{
              email: (decodedToken as TokenType).email,
              message:
                "Hi! I need to upgrade my plan. Can you help me with that?",
              teamId: (decodedToken as TokenType).teamId,
            }}
            onFinish={(values) => submitForm(values)}
            onFinishFailed={() => console.log("failed")}
            autoComplete="off"
          >
            <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[
                { required: true, message: "Please input your message!" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="Estimated usage"
              name="estimation"
              rules={[
                {
                  required: true,
                  message: "Please input your estimated usage",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} suffix="GB / month" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: 10 }}
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </Flex>
      </Modal>
    </div>
  );
};
