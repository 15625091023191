import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useLogs } from "../../providers/LogProvider";
import { Button, Flex, Input } from "antd";
import dayjs from "dayjs";
import { DIRECTIONS } from "../../api/types";

import utc from "dayjs/plugin/utc";
import { TimePickers } from "../../components/TimePickers";
import { ActiveFilters } from "../../components/ActiveFilters";
import { LiveTail } from "../../components/LiveTail";
import { ClearAllFilters } from "../../components/ClearAllFilters";
import { Table } from "../../components/LogTable/Table";
import { WorkerFilter } from "../../components/WorkerFilter";

dayjs.extend(utc);

const { Search } = Input;

const INITIAL_INTERVAL = 2; //hours

export const Logs = () => {
  const {
    getLogs,
    logs,
    activeApp,
    apps,
    searchPhrase,
    filters,
    setFilters,
    liveTail,
    resetLogs,
    highlightedLog,
    setHighlightedLog,
    responseTime,
    requestTime,
    setIsLoading,
  } = useLogs();

  const [sp, setSearchParams] = useSearchParams();
  const [tempSearch, setTempSearch] = useState<string>("");

  useEffect(() => {
    if (logs.length === 0) {
      setIsLoading(true);
    }
  }, []);

  useEffect(() => {
    if (searchPhrase?.length === 0 || searchPhrase === null) {
      setTempSearch("");
    }
  }, [searchPhrase]);

  useEffect(() => {
    if (
      filters &&
      filters.timestamp === "yes" &&
      highlightedLog &&
      logs.length > 0 &&
      responseTime > requestTime
    ) {
      document.querySelector(`#${highlightedLog}`)?.scrollIntoView();
    }
  }, [logs, highlightedLog, filters]);

  useEffect(() => {
    if (filters === null) {
      const currFilters: any = {};
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.size === 0) {
        const now = new Date();
        currFilters.dateFrom = now.getTime().toString();
        currFilters.dateTo = now.getTime().toString();
        currFilters.direction = DIRECTIONS.before;
        if (!currFilters.activeApp) {
          currFilters.activeApp = apps[0].id;
        }
        setFilters(currFilters);
        return;
      }
      for (const [key, value] of searchParams.entries()) {
        if (key === "worker") {
          //check if currFilters has key worker, if not assign empty array and push value, otherwise push value to existing array
          if (currFilters[key]) {
            currFilters[key].push(value);
          } else {
            currFilters[key] = [value];
          }
          continue;
        }
        if (key === "text") {
          currFilters.direction = DIRECTIONS.middle;
          setTempSearch(value);
        }
        // eslint-disable-next-line
        // @ts-ignore
        currFilters[key] = value;
      }

      setFilters(currFilters);
    }
  }, [filters]);

  useEffect(() => {
    const token = localStorage.getItem("token") || null;
    if (!token || filters === null) {
      return;
    }

    if (filters.timestamp !== "yes" && highlightedLog) {
      setHighlightedLog(null);
    }
    setSearchParams(filters);
    const dateFrom = filters.dateFrom
      ? new Date(parseInt(filters.dateFrom))
      : new Date();

    const dateTo = filters.dateTo
      ? new Date(parseInt(filters.dateTo))
      : new Date();
    const direction = filters.direction || DIRECTIONS.middle;
    getLogs({
      appId: filters.activeApp,
      text: filters.text || null,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      direction,
      worker: filters.worker || [],
    });
    if (tempSearch.length > 0 && !filters.text) {
      setTempSearch("");
    }
  }, [filters]);

  const handleSearchPhraseChange = (phrase: string) => {
    if (!filters) {
      return;
    }
    const activeFilters = { ...filters };
    if (activeFilters.dateFrom === activeFilters.dateTo && phrase.length > 0) {
      activeFilters.dateFrom = dayjs().subtract(2, "h").valueOf().toString();
      activeFilters.dateTo = dayjs().valueOf().toString();
      activeFilters.relativeTime = "2#h";
      activeFilters.direction = DIRECTIONS.middle;
    }

    if (activeFilters.relativeTime && phrase.length > 0) {
      const now = dayjs();
      const relativeUnits = activeFilters.relativeTime.split("#");
      activeFilters.dateFrom = now
        .subtract(
          parseInt(relativeUnits[0]),
          relativeUnits[1] as dayjs.ManipulateType | undefined,
        )
        .valueOf()
        .toString();
      activeFilters.dateTo = now.valueOf().toString();
    }
    if (liveTail) {
      resetLogs();
    }
    setFilters({ ...activeFilters, text: phrase });
  };

  const showPrevLogs = () => {
    if (!filters) {
      return;
    }
    setFilters({
      ...filters,
      direction: DIRECTIONS.before,
      dateFrom: new Date(logs[0].date).getTime().toString(),
      dateTo: new Date(logs[0].date).getTime().toString(),
    });
  };

  const showNextLogs = () => {
    if (!filters) {
      return;
    }
    setFilters({
      ...filters,
      direction: DIRECTIONS.after,
      dateFrom: new Date(logs[logs.length - 1].date).getTime().toString(),
      dateTo: new Date(logs[logs.length - 1].date).getTime().toString(),
    });
  };

  return (
    <>
      <Flex justify="space-between" align="center" style={{ margin: "20px 0" }}>
        <Search
          value={tempSearch}
          placeholder="Filter logs - press enter to search"
          onChange={(e) => setTempSearch(e.target.value)}
          allowClear
          style={{ margin: "0 10px 0 0 ", width: "calc(100% - 300px)" }}
          onSearch={(value) => handleSearchPhraseChange(value)}
        />
        <TimePickers key={activeApp} />
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "10px" }}
      >
        <Flex align="center">
          <LiveTail />
          <WorkerFilter />
        </Flex>

        <Flex justify="flex-end" align="center">
          <Button
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={showPrevLogs}
          >
            Prev
          </Button>
          {filters && (
            <Button
              type="primary"
              onClick={showNextLogs}
              disabled={
                dayjs(parseInt(filters.dateTo)).add(INITIAL_INTERVAL, "h") >
                dayjs()
              }
            >
              Next
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "10px" }}
      >
        <ActiveFilters />
        <ClearAllFilters />
      </Flex>

      <Table logs={logs} />
    </>
  );
};
