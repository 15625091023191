import styled from "styled-components";

export const StyledLiveTail = styled.div`
  border: 1px solid #424242;
  background: #141414;
  border-radius: 6px;
  height: 32px;
  display: flex;
  align-items: center;
`;
