import { createContext, useContext, useState } from "react";
import { AlertsContextType, AlertType } from "./types";
import { createAlert, deleteAlert, editAlert, getAlerts } from "../api/alerts";
import { CreateAlertRequestType } from "../api/types";
import { AuthContext } from "./AuthProvider";

const defaultAlertsContext: AlertsContextType = {
  alerts: null,
  getAlerts: async () => {},
  createAlert: async () => {},
  deleteAlert: async () => {},
  editAlert: async () => {},
  resetAlertsState: () => {},
};

export const AlertsContext =
  createContext<AlertsContextType>(defaultAlertsContext);

export const AlertsProvider = ({ children }: { children: JSX.Element }) => {
  const { onLogout } = useContext(AuthContext);
  const [alerts, setAlerts] = useState<AlertType[] | null>(null);

  const handleGetAlerts = async () => {
    const token = localStorage.getItem("token") || null;
    if (!token) {
      onLogout();
      return;
    }

    const response = await getAlerts(token);
    if (response.success) {
      setAlerts(response.alerts as AlertType[]);
    }
  };

  const handleCreateAlert = async (data: CreateAlertRequestType) => {
    const token = localStorage.getItem("token") || null;
    if (!token) {
      onLogout();
      return;
    }

    await createAlert(token, data);
    await handleGetAlerts();
  };

  const handleDeleteAlert = async (id: string) => {
    const token = localStorage.getItem("token") || null;
    if (!token) {
      onLogout();
      return;
    }

    await deleteAlert(token, id);
    await handleGetAlerts();
  };

  const handleEditAlert = async (data: AlertType) => {
    const token = localStorage.getItem("token") || null;
    if (!token) {
      onLogout();
      return;
    }

    await editAlert(token, data);
    await handleGetAlerts();
  };

  const resetAlertsState = () => {
    setAlerts(null);
  };

  const value = {
    alerts,
    getAlerts: handleGetAlerts,
    createAlert: handleCreateAlert,
    deleteAlert: handleDeleteAlert,
    editAlert: handleEditAlert,
    resetAlertsState,
  };

  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  );
};

export const useAlerts = () => {
  return useContext(AlertsContext);
};
