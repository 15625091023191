import { createContext, useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AppWorkerType,
  FiltersType,
  LogContextType,
  PlanDetailsType,
  SingleAppType,
  StatsMetaType,
  StatsType,
} from "./types";
import { AuthContext } from "./AuthProvider";
import { notification } from "antd";
import { getApps, getLogs, getPlan, getStats } from "../api/logs";
import { DIRECTIONS, GetLogsRequestType, LogType } from "../api/types";
import dayjs, { Dayjs } from "dayjs";
import { parseStats } from "../utils/parseStats";

const defaultLogContext = {
  apps: [],
  getApps: () => {},
  onAppChange: () => {},
  activeApp: "",
  logs: [],
  getLogs: () => {},
  fromDate: "",
  toDate: dayjs(),
  searchPhrase: null,
  liveTail: 0,
  setSearchPhrase: () => {},
  setToDate: () => {},
  setFromDate: () => {},
  setLiveTail: () => {},
  openedLogs: [],
  setOpenedLogs: () => {},
  getStats: () => {},
  stats: [],
  statsMeta: {},
  filters: null,
  setFilters: () => {},
  clearFilters: () => {},
  resetLogs: () => {},
  workers: [],
  setWorkers: () => {},
  planDetails: null,
  getPlanDetails: () => {},
  highlightedLog: null,
  setHighlightedLog: () => {},
  isLoading: false,
  setIsLoading: () => {},
  requestTime: 0,
  responseTime: 0,
  setRequestTime: () => {},
  setResponseTime: () => {},
  clearState: () => {},
};

export const LogContext = createContext<LogContextType>(defaultLogContext);

export const LogProvider = ({ children }: { children: JSX.Element }) => {
  const [requestTime, setRequestTime] = useState<number>(0);
  const [responseTime, setResponseTime] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uniqueIds, setUniqueIds] = useState<Set<string>>(new Set());
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<Dayjs>(dayjs().subtract(15, "s"));
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [logs, setLogs] = useState<LogType[]>([]);
  const [apps, setApps] = useState<SingleAppType[]>([]);
  const [workers, setWorkers] = useState<AppWorkerType[]>([]);
  const [liveTail, setLiveTail] = useState<number>(0);
  const [openedLogs, setOpenedLogs] = useState<string[]>([]);
  const [stats, setStats] = useState<StatsType[]>([]);
  const [statsMeta, setStatsMeta] = useState<StatsMetaType>({});
  const [activeApp, setActiveApp] = useState<string>("");
  const [userUnauthorized, setUserUnauthorized] = useState<boolean>(false);
  const { onLogout } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<FiltersType | null>(null);
  const [planDetails, setPlanDetails] = useState<PlanDetailsType>(null);
  const [highlightedLog, setHighlightedLog] = useState<string | null>(null);

  useEffect(() => {
    if (userUnauthorized) {
      onLogout();
    }
  }, [userUnauthorized]);

  useEffect(() => {
    if (liveTail) {
      setUniqueIds(new Set());
      setLogs([]);
    }
  }, [liveTail]);

  const clearFilters = () => {
    if (apps.length > 0) {
      const now = dayjs();
      if (liveTail) setLiveTail(0);
      if (fromDate) setFromDate("");
      setFilters({
        dateFrom: now.valueOf().toString(),
        dateTo: now.valueOf().toString(),
        activeApp,
        direction: DIRECTIONS.before,
      });
    }
  };

  const handleAppChange = (appId: string, apps: SingleAppType[]) => {
    setActiveApp(appId);
    setWorkers(apps.find((app) => app.id === appId)?.workers || []);
    const appName = apps.find((app) => app.id === appId)?.name;
    const now = dayjs();
    setLogs([]);

    setFilters({
      dateFrom: now.valueOf().toString(),
      dateTo: now.valueOf().toString(),
      activeApp: appId || activeApp || apps[0].id,
      direction: DIRECTIONS.before,
    });

    if (appName) {
      localStorage.setItem("appId", appName);
    }
  };

  const handleGetApps = async () => {
    const token = localStorage.getItem("token") || null;
    if (!token) {
      setUserUnauthorized(true);
      return;
    }

    const response = await getApps(token);
    if (response) {
      setApps(response);
      if (searchParams.get("activeApp")) {
        if (
          response.find(
            (app: SingleAppType) => app.id === searchParams.get("activeApp"),
          )
        ) {
          handleAppChange(searchParams.get("activeApp") as string, response);
        } else {
          handleAppChange(response[0].id, response);
          notification.warning({
            message: "App not found",
            description: "App from query not found",
          });
        }
      } else if (localStorage.getItem("appId")) {
        const findApp = response.find(
          (app: SingleAppType) => app.name === localStorage.getItem("appId"),
        );
        if (findApp) {
          handleAppChange(findApp.id, response);
        } else {
          handleAppChange(response[0].id, response);
        }
      } else {
        handleAppChange(response[0].id, response);
      }
    } else {
      notification.error({
        message: "Error",
        description: "There was an error while fetching user's apps",
      });
    }
  };

  const handleGetStats = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token") || null;
    if (!token) {
      setUserUnauthorized(true);
      return;
    }

    const response = await getStats(token);
    setIsLoading(false);
    if (response) {
      const parsedStats = parseStats(response);
      setStats(parsedStats.data);
      setStatsMeta(parsedStats.meta);
    } else {
      notification.error({
        message: "Error",
        description: "There was an error while fetching stats",
      });
    }
  };

  const handleGetPlan = async () => {
    const token = localStorage.getItem("token") || null;
    if (!token) {
      setUserUnauthorized(true);
      return;
    }

    const response = await getPlan(token);
    if (response) {
      setPlanDetails(response);
    }
  };

  const resetLogs = () => {
    setLogs([]);
    setUniqueIds(new Set());
  };

  const handleGetLogs = async (payload: GetLogsRequestType) => {
    setIsLoading(true);
    let response: LogType[] = [];
    const token = localStorage.getItem("token") || null;

    if (!token) {
      setUserUnauthorized(true);
      return;
    }

    response = await getLogs(token, payload);
    if (response) {
      if (liveTail) {
        //make sure we don't add duplicates
        const ids = uniqueIds;
        const uniqueLogs = response.filter((log) => {
          if (ids.has(log.id)) {
            return false;
          }
          ids.add(log.id);
          return true;
        });
        const parsedLogs = uniqueLogs.map((log) => ({
          ...log,
          id: `la_${log.id}`,
        }));
        setUniqueIds(ids);
        setLogs([...logs, ...parsedLogs]);
      } else {
        const parsedLogs = response.map((log) => ({
          ...log,
          id: `la_${log.id}`,
        }));
        setLogs(parsedLogs);
      }
      setResponseTime(Date.now());
    } else {
      notification.error({
        message: "Error",
        description: "There was an error while downloading logs",
      });
    }
    setIsLoading(false);
  };

  const clearState = () => {
    localStorage.removeItem("appId");
    resetLogs();
    setRequestTime(0);
    setResponseTime(0);
    setFromDate("");
    setSearchPhrase(null);
    setApps([]);
    setWorkers([]);
    setLiveTail(0);
    setStats([]);
    setStatsMeta({});
    setActiveApp("");
    setFilters(null);
    setPlanDetails(null);
  };

  const value = {
    apps,
    activeApp,
    logs,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    searchPhrase,
    liveTail,
    setLiveTail,
    setSearchPhrase,
    openedLogs,
    setOpenedLogs,
    getApps: handleGetApps,
    getLogs: handleGetLogs,
    onAppChange: handleAppChange,
    stats,
    statsMeta,
    getStats: handleGetStats,
    filters,
    setFilters,
    clearFilters,
    resetLogs,
    workers,
    setWorkers,
    planDetails,
    getPlanDetails: handleGetPlan,
    highlightedLog,
    setHighlightedLog,
    isLoading,
    setIsLoading,
    requestTime,
    responseTime,
    setRequestTime,
    setResponseTime,
    clearState,
  };

  return <LogContext.Provider value={value}>{children}</LogContext.Provider>;
};

export const useLogs = () => {
  return useContext(LogContext);
};
