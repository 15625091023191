import styled from "styled-components";

export const StyledProgressBarContainer = styled.div`
  height: 2px;
  width: calc(100% - 8px);
  background: white;
  margin: 10px auto;
  cursor: pointer;
`;

export const StyledProgressBar = styled.div<{ bg: string; width: number }>`
  width: ${({ width }) => width}%;
  background: ${({ bg }) => bg};
  height: 2px;
`;
