import styled from "styled-components";

export const StyledUsage = styled.div<{ withoutBorder?: boolean }>`
  margin-top: 20px;
  padding: 10px 20px;
  border-right: ${({ withoutBorder }) =>
    withoutBorder ? "none" : "1px solid #424242"};
`;

export const UsageDisclaimer = styled.p`
  font-size: 12px;
  color: #8c8c8c;
  margin-top: 10px;
  text-style: italic;
  line-height: 1.5;
`;
