export const parseDataUnits = (
  input: number,
  unit?: string,
): { value: number; unit: string } => {
  if (unit) {
    if (unit === "KB") {
      return { value: input / 1024, unit: "KB" };
    } else if (unit === "MB") {
      return { value: input / (1024 * 1024), unit: "MB" };
    } else if (unit === "GB") {
      return { value: input / (1024 * 1024 * 1024), unit: "GB" };
    }
  }
  if (input < 1024 * 1024) {
    return { value: input / 1024, unit: "KB" };
  } else if (input < 1024 * 1024 * 1024) {
    return { value: input / (1024 * 1024), unit: "MB" };
  } else {
    return { value: input / (1024 * 1024 * 1024), unit: "GB" };
  }
};
