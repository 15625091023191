import { Flex } from "antd";
import { BarContainer, StyledMenuButton } from "./styled";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../ProgressBar";

type MenuButtonProps = {
  isCollapsed: boolean;
  isActive: boolean;
  children: React.ReactNode;
  label: string;
};

export const MenuButton = ({
  isCollapsed,
  isActive,
  children,
  label,
}: MenuButtonProps) => {
  const navigate = useNavigate();
  return (
    <StyledMenuButton isActive={isActive} onClick={() => navigate("/usage")}>
      <Flex align="center">
        {children}
        <span style={{ marginLeft: "10px" }}>{!isCollapsed && label}</span>
      </Flex>
      <BarContainer>
        <ProgressBar />
      </BarContainer>
    </StyledMenuButton>
  );
};
