import { Button } from "antd";
import { useLogs } from "../../providers/LogProvider";

export const ClearAllFilters = () => {
  const { clearFilters } = useLogs();
  return (
    <Button type="default" onClick={clearFilters}>
      Clear all filters
    </Button>
  );
};
