import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ParsedStatsType } from "./index";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const RenderChart = ({
  data,
  unit,
}: {
  data: ParsedStatsType[];
  unit: string;
}) => {
  const config = {
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title: function (context: any) {
            return "Data usage at " + context[0].label;
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: function (context: any) {
            return `${context.dataset.label}: ${context.parsed.y.toFixed(
              2,
            )}${unit}`;
          },
        },
      },
    },
    responsive: true,
    parsing: {
      xAxisKey: "date",
      yAxisKey: "totalBytes",
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: (value: any) => {
            return `${value} ${unit}`;
          },
        },
      },
    },
  };

  return (
    <Bar
      style={{ maxHeight: "60vh" }}
      options={config}
      data={{
        datasets: data,
      }}
    />
  );
};
