import CloseOutlined from "@ant-design/icons/CloseOutlined";

type BannerNotificationProps = {
  headline: string;
  children: React.ReactNode;
  closable?: boolean;
  onClose?: () => void;
};

export const BannerNotification = ({
  headline,
  children,
  closable,
  onClose,
}: BannerNotificationProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#583c69a6",
        color: "white",
        padding: "20px 10px",
        width: "100%",
        textAlign: "center",
      }}
    >
      {closable && (
        <div
          onClick={onClose}
          style={{
            position: "absolute",
            top: "20px",
            right: "30px",
            cursor: "pointer",
          }}
        >
          <CloseOutlined />
        </div>
      )}
      <h3 style={{ marginBottom: "10px" }}>{headline}</h3>
      <div
        style={{
          lineHeight: 1.5,
          maxWidth: "600px",
          textAlign: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};
