import { styled } from "styled-components";

export const StyledTable = styled.div`
  position: relative;
  color: white;
  font-family: "Inconsolata", monospace;
  font-size: 13px;
  line-height: 1.5;
  border: 1px solid #424242;
  border-radius: 6px;
  background: rgb(20, 20, 20);
  letter-spacing: 0.5px;
  overflow-y: scroll;
  height: calc(100vh - 232px);
`;

export const StyledDate = styled.span`
  color: #7691a7;
`;

export const StyledWorker = styled.span`
  color: #e53ee5;
  padding: 0 10px;
`;

export const StyledRow = styled.div<{ withBackground: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 12px 10px;
  background: ${(props) =>
    props.withBackground ? "#57a2e545" : "transparent"};

  &:hover {
    background: rgb(119 194 255 / 16%);
  }
`;
