import { Button, Flex, Modal, Input, Typography, DatePicker } from "antd";

import { useEffect, useState } from "react";
import { CalendarOutlined, RightOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import { useLogs } from "../../providers/LogProvider";
import { DIRECTIONS } from "../../api/types";

const { RangePicker, TimePicker } = DatePicker;
const { Text } = Typography;
type TimeUnitsType = "m" | "h" | "d" | "w";

export const TimePickers = () => {
  const { fromDate, setFromDate, setFilters, filters, setLiveTail } = useLogs();
  const [rangeDates, setRangeDates] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [now, setNow] = useState(dayjs());
  const [defaultRelativeTimeActive, setDefaultRelativeTimeActive] =
    useState<boolean>(false);
  const [mode, setMode] = useState<"relative" | "absolute">(
    filters && filters.relativeTime ? "relative" : "absolute",
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [activeRelativeTime, setActiveRelativeTime] = useState<{
    value: number;
    unit: TimeUnitsType;
  } | null>(null);

  useEffect(() => {
    let bool = false;
    if (activeRelativeTime) {
      if (
        (activeRelativeTime.unit === "m" || activeRelativeTime.unit === "h") &&
        activeRelativeTime.value === 1
      ) {
        bool = true;
      }
    }
    setDefaultRelativeTimeActive(bool);
  }, [activeRelativeTime]);

  useEffect(() => {
    if (!filters) return;
    if (!filters.relativeTime) {
      setDefaultRelativeTimeActive(false);
      setActiveRelativeTime(null);
    } else {
      const [value, unit] = filters.relativeTime.split("#");
      setActiveRelativeTime({
        value: parseInt(value),
        unit: unit as TimeUnitsType,
      });
      setMode("relative");
    }
  }, [filters]);

  const handleOk = () => {
    if (!filters) return;
    if (mode === "absolute" && startTime && endTime) {
      if (fromDate) {
        setFromDate("");
      }
      const activeFilters = filters;
      delete activeFilters.timestamp;
      delete activeFilters.relativeTime;

      setFilters({
        ...activeFilters,
        dateFrom: startTime.valueOf().toString(),
        dateTo: endTime.valueOf().toString(),
      });
    }
    setModalVisible(false);
  };

  const handleDateRangeChange = (dates: any) => {
    setRangeDates(dates);
    setStartTime(
      dayjs(dates[0]).set("hour", 0).set("minute", 0).set("second", 0),
    );
    setEndTime(
      dayjs(dates[1]).set("hour", 23).set("minute", 59).set("second", 59),
    );
  };

  useEffect(() => {
    if (modalVisible) {
      setNow(dayjs());
    }
  }, [modalVisible]);

  const handleRelativeTime = (value: number, unit: TimeUnitsType) => {
    if (!filters) return;
    setActiveRelativeTime({
      value,
      unit,
    });
    if (fromDate) {
      setFromDate("");
    }

    const activeFilters = filters;
    delete activeFilters.timestamp;
    setFilters({
      ...activeFilters,
      relativeTime: `${value}#${unit}`,
      dateTo: now.valueOf().toString(),
      dateFrom: now.subtract(value, unit).valueOf().toString(),
    });
    setModalVisible(false);
  };

  const handleClearButton = () => {
    if (!filters) return;
    setRangeDates([null, null]);
    setStartTime(null);
    setEndTime(null);
    setActiveRelativeTime(null);
    setModalVisible(false);

    const activeFilters = {
      ...filters,
      direction: DIRECTIONS.before,
    };

    if (activeFilters.relativeTime) {
      delete activeFilters.relativeTime;
    }

    setFilters({
      ...activeFilters,
      dateFrom: now.valueOf().toString(),
      dateTo: now.valueOf().toString(),
    });
  };

  const TimeRow = ({
    unitName,
    unit,
    values,
  }: {
    unitName: string;
    unit: TimeUnitsType;
    values: number[];
  }) => (
    <Flex align="center" style={{ marginTop: "15px" }}>
      <div style={{ width: "80px" }}>{unitName}</div>
      {values.map((value) => (
        <TimeUnitButton
          onClick={() => handleRelativeTime(value, unit)}
          value={value}
          unit={unit}
        />
      ))}
    </Flex>
  );

  const TimeUnitButton = ({
    value,
    unit,
    onClick,
  }: {
    value: number;
    unit: TimeUnitsType;
    onClick: () => void;
  }) => (
    <Button
      type={
        activeRelativeTime &&
        activeRelativeTime.value === value &&
        activeRelativeTime.unit === unit
          ? "primary"
          : "default"
      }
      style={{ width: "45px", marginRight: "15px" }}
      onClick={onClick}
    >
      {value}
    </Button>
  );

  if (!filters) return null;
  return (
    <>
      <Flex
        justify="space-between"
        style={{
          border: "1px solid #424242",
          background: "#141414",
          borderRadius: "6px",
          width: "300px",
        }}
      >
        {mode === "relative" ? (
          <>
            <Button
              style={{ marginRight: "15px" }}
              type={
                activeRelativeTime &&
                activeRelativeTime.value === 1 &&
                activeRelativeTime.unit === "m"
                  ? "primary"
                  : "text"
              }
              onClick={() =>
                activeRelativeTime &&
                activeRelativeTime.value === 1 &&
                activeRelativeTime.unit === "m"
                  ? setModalVisible(true)
                  : handleRelativeTime(1, "m")
              }
            >
              1m
            </Button>
            <Button
              style={{ marginRight: "15px", color: "white" }}
              type={
                activeRelativeTime &&
                activeRelativeTime.value === 1 &&
                activeRelativeTime.unit === "h"
                  ? "primary"
                  : "text"
              }
              onClick={() =>
                activeRelativeTime &&
                activeRelativeTime.value === 1 &&
                activeRelativeTime.unit === "h"
                  ? setModalVisible(true)
                  : handleRelativeTime(1, "h")
              }
            >
              1h
            </Button>

            <div>
              {!defaultRelativeTimeActive && activeRelativeTime && (
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  {activeRelativeTime.value}
                  {activeRelativeTime.unit}
                </Button>
              )}
              <Button
                type="text"
                onClick={() => [setLiveTail(0), setModalVisible(true)]}
              >
                <CalendarOutlined />
              </Button>
            </div>
          </>
        ) : (
          <>
            {filters.dateFrom === filters.dateTo || !startTime ? (
              <Button type="text" onClick={() => setModalVisible(true)}>
                Start date
                <RightOutlined />
                End date
              </Button>
            ) : (
              <Button type="text" onClick={() => setModalVisible(true)}>
                {dayjs(parseInt(filters.dateFrom)).format("YYYY/MM/DD HH:mm")}
                <RightOutlined />
                {dayjs(parseInt(filters.dateTo)).format("YYYY/MM/DD HH:mm")}
              </Button>
            )}

            <Button
              type="text"
              onClick={() => [setLiveTail(0), setModalVisible(true)]}
            >
              <CalendarOutlined />
            </Button>
          </>
        )}
      </Flex>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleOk}
        width={700}
        footer={[
          <Flex style={{ marginTop: "20px" }} justify="space-between">
            <Button key="back" onClick={handleClearButton}>
              Clear
            </Button>
            <div>
              <Button
                key="submit"
                type="text"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={handleOk}>
                Apply
              </Button>
            </div>
          </Flex>,
        ]}
      >
        <Button
          type={mode === "relative" ? "primary" : "default"}
          onClick={() => setMode("relative")}
        >
          Relative
        </Button>
        <Button
          type={mode === "absolute" ? "primary" : "default"}
          onClick={() => setMode("absolute")}
        >
          Absolute
        </Button>
        {mode === "relative" ? (
          <Flex vertical>
            <TimeRow unitName="Minutes" unit="m" values={[1, 5, 10, 15, 30]} />
            <TimeRow unitName="Hours" unit="h" values={[1, 2, 3, 6, 8, 12]} />
            <TimeRow unitName="Days" unit="d" values={[1, 2, 3, 4, 5, 6]} />
            <TimeRow unitName="Weeks" unit="w" values={[1, 2, 3, 4]} />

            <Flex justify="space-between" style={{ marginTop: "20px" }}>
              <div style={{ marginRight: "20px" }}>
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Start Date
                </Text>
                <Input
                  disabled
                  placeholder={
                    activeRelativeTime
                      ? dayjs(now)
                          .subtract(
                            activeRelativeTime.value,
                            activeRelativeTime.unit,
                          )
                          .format("YYYY/MM/DD")
                      : "YYYY/MM/DD"
                  }
                />
              </div>
              <div style={{ marginRight: "20px" }}>
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Start Time
                </Text>
                <Input
                  disabled
                  placeholder={
                    activeRelativeTime
                      ? dayjs(now)
                          .subtract(
                            activeRelativeTime.value,
                            activeRelativeTime.unit,
                          )
                          .format("HH:mm:ss")
                      : "00:00:00"
                  }
                />
              </div>
              <div style={{ marginRight: "20px" }}>
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  End Date
                </Text>
                <Input
                  disabled
                  placeholder={
                    activeRelativeTime
                      ? dayjs(now).format("YYYY/MM/DD")
                      : "YYYY/MM/DD"
                  }
                />
              </div>
              <div>
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  End Time
                </Text>
                <Input
                  disabled
                  placeholder={
                    activeRelativeTime
                      ? dayjs(now).format("HH:mm:ss")
                      : "00:00:00"
                  }
                />
              </div>
            </Flex>
          </Flex>
        ) : (
          <div>
            <Flex vertical style={{ marginTop: "20px" }}>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                Date range
              </Text>
              <RangePicker
                value={rangeDates}
                onChange={handleDateRangeChange}
                disabledDate={(current) =>
                  current && current > dayjs().endOf("day")
                }
              />
            </Flex>
            <Flex style={{ marginTop: "20px" }}>
              <Flex vertical style={{ marginRight: "20px" }}>
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Start Time
                </Text>
                <TimePicker
                  value={startTime}
                  onChange={(val) => setStartTime(val)}
                />
              </Flex>
              <Flex vertical>
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  End Time
                </Text>
                <TimePicker
                  value={endTime}
                  onChange={(val) => setEndTime(val)}
                />
              </Flex>
            </Flex>
          </div>
        )}
      </Modal>
    </>
  );
};
