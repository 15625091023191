import { StyledProgressBar, StyledProgressBarContainer } from "./styled";
import { useLogs } from "../../providers/LogProvider";
import { NOTIFICATION_TRESHOLD } from "../PlanNotifcation";

export const ProgressBar = () => {
  const { planDetails } = useLogs();

  const getProgressBarColor = (percent: number) => {
    if (percent < NOTIFICATION_TRESHOLD) {
      return "#00FF00";
    } else if (percent < 90) {
      return "#FFA500";
    } else {
      return "#FF0000";
    }
  };

  if (!planDetails) {
    return null;
  }

  return (
    <StyledProgressBarContainer>
      <StyledProgressBar
        width={planDetails?.usedPercent}
        bg={getProgressBarColor(planDetails?.usedPercent || 0)}
      ></StyledProgressBar>
    </StyledProgressBarContainer>
  );
};
