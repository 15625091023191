export type LogType = {
  date: string;
  id: string;
  meta: null | string;
  score: null;
  service: string;
  text: string;
  worker: string;
  workerParent: string | null;
  metadata?: string;
};

export const DIRECTIONS = {
  before: "before",
  after: "after",
  middle: "middle",
};

export type DirectionType = (typeof DIRECTIONS)[keyof typeof DIRECTIONS];

export type GetLogsRequestType = {
  appId: string;
  text: string | null;
  dateFrom: string;
  dateTo: string;
  direction: DirectionType;
  worker: string[];
};

export type SendFormRequestType = {
  email: string;
  teamId: string;
  message: string;
  estimation: number;
};

export type CreateAlertRequestType = {
  appId: string;
  text: string;
  checkType: number; // nwm co to, ale ma byc 1
  channels: [
    {
      channelType: number; // 1 - email
      params: {
        emails: string[];
      };
    },
  ];
};
