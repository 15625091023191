import { useEffect, useState } from "react";

import {
  Flex,
  Typography,
  Table,
  Switch,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tooltip,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useAlerts } from "../../providers/AlertProvider";
import { useLogs } from "../../providers/LogProvider";
import { useAuth } from "../../providers/AuthProvider";
import { AlertType } from "../../providers/types";

const { Title } = Typography;

const MODE_CREATE = "create";
const MODE_EDIT = "edit";

export const Alerts = () => {
  const [mode, setMode] = useState<string>(MODE_CREATE);

  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const { alerts, getAlerts, createAlert, deleteAlert, editAlert } =
    useAlerts();
  const { apps, activeApp } = useLogs();
  const { email } = useAuth();
  const [activeAlert, setActiveAlert] = useState<AlertType | null>(null);

  const handlePopupOpen = (open: boolean, mode?: string) => {
    if (open) {
      setMode(mode || MODE_CREATE);

      setPopupVisible(true);
    } else {
      setActiveAlert(null);
      setPopupVisible(false);
    }
  };

  const handleAlertCreation = (values: {
    appId: string;
    emails: string[];
    text: string;
  }) => {
    console.log(values);
    handlePopupOpen(false);
    const emails = values.emails;

    if (mode === MODE_EDIT && activeAlert) {
      editAlert({
        ...activeAlert,
        text: values.text,
        appId: values.appId,
        checkType: 1,
        isActive: activeAlert.isActive,
        channels: [
          {
            channelType: 1,
            params: { emails },
          },
        ],
      });
    } else {
      createAlert({
        appId: values.appId,
        text: values.text,
        checkType: 1,
        channels: [
          {
            channelType: 1,
            params: {
              emails,
            },
          },
        ],
      });
    }
  };

  const handleDelete = () => {
    if (activeAlert) {
      handlePopupOpen(false);
      deleteAlert(activeAlert.id);
    }
  };

  useEffect(() => {
    if (alerts === null) {
      getAlerts();
    }
  }, [alerts]);

  const columns = [
    {
      title: "",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean, row: AlertType) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={isActive}
          onChange={(checked) => {
            return editAlert({ ...row, isActive: checked });
          }}
        />
      ),
    },
    {
      title: "Application",
      dataIndex: "appId",
      key: "appId",
      render: (appId: string) => {
        const app = apps.find((app) => app.id === appId);
        return app?.name;
      },
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
      render: (_: string, row: AlertType) => {
        return row.channels[0].params.emails.map((el) => el).join(", ");
      },
    },
    {
      title: "Last triggered",
      dataIndex: "lastTriggered",
      key: "lastTriggered",
      render: (lastTriggered: Date | null) => {
        return lastTriggered ? new Date(lastTriggered).toLocaleString() : "-";
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_: string, record: AlertType) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => [
              setActiveAlert(record),
              handlePopupOpen(true, MODE_EDIT),
            ]}
          >
            <EditOutlined />
          </div>
        );
      },
    },
  ];

  const getInitialValues = () => {
    const initialValues: Record<string, string | string[]> = {};
    initialValues.appId = activeAlert?.appId || activeApp;
    initialValues.text = activeAlert?.text || "";
    initialValues.emails = activeAlert?.channels[0].params.emails || [email];

    return initialValues;
  };

  return (
    <div>
      <Flex vertical align="center" justify="center" style={{ marginTop: 20 }}>
        <Title style={{ marginBottom: 20 }} level={4}>
          Alerts
        </Title>
        {alerts === null || alerts.length === 0 ? (
          <>
            <Typography.Text type="secondary" style={{ marginBottom: 20 }}>
              You do not have any alerts yet. Create one to get notified.
            </Typography.Text>
            <Button
              type="primary"
              onClick={() => handlePopupOpen(true, MODE_CREATE)}
            >
              Create alert
            </Button>
          </>
        ) : (
          <>
            <Flex
              justify="flex-end"
              style={{ width: "100%", marginBottom: 20 }}
            >
              <Button
                type="primary"
                onClick={() => handlePopupOpen(true, MODE_CREATE)}
              >
                Create alert
              </Button>
            </Flex>
            <Table
              style={{ width: "100%" }}
              dataSource={alerts}
              columns={columns}
              pagination={false}
            />

            <Flex
              style={{
                marginTop: 30,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                You are eligible to send up to 20 emails per day through our
                system.
                <br /> Please note that each alert notification sent counts
                towards this daily limit based on the number of recipients.
                <br /> This limit is experimental and may change at any time
                without notice
              </Typography.Text>
            </Flex>
          </>
        )}
        {popupVisible && (
          <Modal
            title={mode === MODE_CREATE ? "Create alert" : "Edit alert"}
            open={popupVisible}
            onCancel={() => handlePopupOpen(false)}
            footer={null}
          >
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600, width: "100%" }}
              initialValues={getInitialValues()}
              onFinish={(values) => handleAlertCreation(values)}
              onFinishFailed={() => console.log("failed")}
              autoComplete="off"
            >
              <Form.Item
                label="Application"
                name="appId"
                rules={[
                  {
                    required: true,
                    message: "Please select your application",
                  },
                ]}
              >
                <Select>
                  {apps.map((app) => (
                    <Select.Option key={app.id} value={app.id}>
                      {app.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Tooltip title="Enter a search phrase (case sensitive) to trigger an alert when matched.">
                <Form.Item
                  label="Alert phrase"
                  name="text"
                  rules={[
                    {
                      required: true,
                      message: "Please input your alert message!",
                    },
                    {
                      min: 5,
                      max: 40,
                      message:
                        "Text lenght should be between 5 to 40 characters",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Tooltip>
              <Form.List name="emails">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Form.Item
                          label={index === 0 ? "E-mail" : ""}
                          required={true}
                          key={field.key}
                          {...(index === 0
                            ? {}
                            : {
                                wrapperCol: {
                                  sm: { span: 16, offset: 8 },
                                },
                              })}
                        >
                          <Flex>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your email!",
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                placeholder="e-mail address"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <DeleteOutlined
                                style={{ margin: "0 8px" }}
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            ) : null}
                          </Flex>
                        </Form.Item>
                      ))}
                      {fields.length < 5 && (
                        <Form.Item wrapperCol={{ sm: { offset: 8 } }}>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            style={{ width: "100%" }}
                          >
                            Add e-mail
                          </Button>
                        </Form.Item>
                      )}
                    </div>
                  );
                }}
              </Form.List>

              <Flex
                style={{ marginTop: 40 }}
                justify={mode === MODE_EDIT ? "space-between" : "flex-end"}
              >
                {mode === MODE_EDIT && (
                  <Button danger onClick={handleDelete}>
                    Delete
                  </Button>
                )}

                <Button
                  type="primary"
                  form="basic"
                  key="submit"
                  htmlType="submit"
                >
                  {mode === MODE_CREATE ? "Create" : "Save"}
                </Button>
              </Flex>
            </Form>
          </Modal>
        )}
      </Flex>
    </div>
  );
};

export default Alerts;
